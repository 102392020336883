import React from "react"
import Layout from "../components/layout"
import Posts from "../components/posts"
import SEO from "../components/seo"
import H1 from "../components/h1"

const title = "Wypoczynek i Relaks"

const WypoczynekRelaks = () => (
    <Layout>
        <SEO title={title} />
        <H1 title={title} page="card"></H1>
        <Posts category="wypoczynek-relaks" />
    </Layout>
)

export default WypoczynekRelaks
